import React from 'react'
import ArchiveCardWrapper from '../../../components/ArchiveCardWrapper/ArchiveCardWrapper'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import SEO from '../../../components/seo'
import { Header1 } from '../../../components/Headers/Headers'
import MonumentCardAsset from '../../../components/MonumentCard/MonumentCardAsset'
import MonumentCardBojownicyLatowicz from '../../../monuments/latowicz/MonumentCardBojownicyLatowicz'

const LatowiczArchive = () => {
  return (
    <ArchiveWrapper>
      <SEO title="Materiały dotyczące gminy Latowicz" />
      <ArchiveCardWrapper>
        <Header1>Materiały dotyczące gminy Latowicz</Header1>
        <MonumentCardBojownicyLatowicz />
        <MonumentCardAsset
          title={
            'Eugeniusz Religa (1909-1995) - międzywojenny nauczyciel i żołnierz, Rocznik Mińskomazowiecki tom 23, 2015 r.'
          }
          author={'Zdzisław Ćmoch'}
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/gm+Latowicz+e_religa_latowicz.mp3'
          }
        />
      </ArchiveCardWrapper>
    </ArchiveWrapper>
  )
}

export default LatowiczArchive
