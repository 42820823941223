import React from 'react'
import MonumentCard from '../../components/MonumentCard/MonumentCard'
import { useStaticQuery, graphql } from 'gatsby'

const MonumentCardBojownicyLatowicz = () => {
  const { file } = useStaticQuery(
    graphql`
      {
        file(
          name: { regex: "/okladka/" }
          relativePath: { regex: "/latowicz/pomnik-bojownicy/" }
        ) {
          childImageSharp {
            fluid(maxHeight: 350) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )

  return (
    <MonumentCard
      fluid={file.childImageSharp.fluid}
      linkTo={'/gminy/latowicz/pomnik-bojownikow-o-niepodleglosc'}
      title={'Pomnik Bojowników o niepodległość'}
    ></MonumentCard>
  )
}

export default MonumentCardBojownicyLatowicz
